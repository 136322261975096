import './App.css';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { useState } from 'react';

function App() {
  //Text for the final product
  const [urlText, setUrlText] = useState('');

  //Region drop down
  const [region, setRegion] = useState('GB');

  //Payment drop down
  const [paymentType, setPaymentType] = useState('either');
  
  //Filter Licences drop down
  const [filterLicences, setfilterLicences] = useState('true');

  //Products
  const [bpoluk_2223_checked, setBpoluk_2223_checked] = useState(false);
  const [bpoluk_2324_checked, setBpoluk_2324_checked] = useState(false);
  const [bpoluk_2425_checked, setBpoluk_2425_checked] = useState(false);
  const [bpoluk_2526_checked, setBpoluk_2526_checked] = useState(false);
  const [bpolie_22_checked, setBpolie_22_checked] = useState(false);
  const [bpolie_23_checked, setBpolie_23_checked] = useState(false);
  const [bpolie_24_checked, setBpolie_24_checked] = useState(false);
  const [bpolie_25_checked, setBpolie_25_checked] = useState(false);
  const [tpm_22_checked, setTpm_22_checked] = useState(false);
  const [tpm_23_checked, setTpm_23_checked] = useState(false);
  const [tpm_24_checked, setTpm_24_checked] = useState(false);
  const [tpm_25_checked, setTpm_25_checked] = useState(false);

  //Ket type ids
  const [bp_ie_22_standard_checked, setBp_ie_22_standard_checked] = useState(false);
  const [bp_ie_22_bureau_checked, setbp_Ie_22_bureau_checked] = useState(false);
  const [bp_ie_23_standard_checked, setBp_ie_23_standard_checked] = useState(false);
  const [bp_ie_23_bureau_checked, setBp_ie_23_bureau_checked] = useState(false);
  const [bp_ie_24_standard_checked, setBp_ie_24_standard_checked] = useState(false);
  const [bp_ie_24_bureau_checked, setBp_ie_24_bureau_checked] = useState(false);
  const [bp_ie_25_standard_checked, setBp_ie_25_standard_checked] = useState(false);
  const [bp_ie_25_bureau_checked, setBp_ie_25_bureau_checked] = useState(false);
  const [bp_uk_2223_standard_checked, setBp_uk_2223_standard_checked] = useState(false);
  const [bp_uk_2223_bureau_checked, setBp_uk_2223_bureau_checked] = useState(false);
  const [bp_uk_2324_standard_checked, setBp_uk_2324_standard_checked] = useState(false);
  const [bp_uk_2324_bureau_checked, setBp_uk_2324_bureau_checked] = useState(false);
  const [bp_uk_2425_standard_checked, setBp_uk_2425_standard_checked] = useState(false);
  const [bp_uk_2425_bureau_checked, setBp_uk_2425_bureau_checked] = useState(false);
  const [bp_uk_2526_standard_checked, setBp_uk_2526_standard_checked] = useState(false);
  const [bp_uk_2526_bureau_checked, setBp_uk_2526_bureau_checked] = useState(false);
  const [tpm_22_standard_checked, setTpm_22_standard_checked] = useState(false);
  const [tpm_22_bureau_checked, setTpm_22_bureau_checked] = useState(false);
  const [tpm_23_standard_checked, setTpm_23_standard_checked] = useState(false);
  const [tpm_23_bureau_checked, setTpm_23_bureau_checked] = useState(false);
  const [tpm_24_standard_checked, setTpm_24_standard_checked] = useState(false);
  const [tpm_24_bureau_checked, setTpm_24_bureau_checked] = useState(false);
  const [tpm_25_standard_checked, setTpm_25_standard_checked] = useState(false);
  const [tpm_25_bureau_checked, setTpm_25_bureau_checked] = useState(false);
  const [bc_ie_standard_checked, setBc_ie_standard_checked] = useState(false);
  const [bc_ie_bureau_checked, setBc_ie_bureau_checked] = useState(false);
  const [bc_uk_standard_checked, setBc_uk_standard_checked] = useState(false);
  const [bc_uk_bureau_checked, setBc_uk_bureau_checked] = useState(false);

    //Voucher
  const [voucherCode, setVoucherCode] = useState('');

  //Employees
  const [employeeCount, setNumberOfEmployees] = useState('');

  function buildUrl() {
    var baseUrl = 'https://id.brightsg.com/licences/purchase/?c=' + region;

    if (bpoluk_2223_checked ||
      bpoluk_2324_checked ||
      bpoluk_2425_checked ||
      bpoluk_2526_checked ||
      bpolie_22_checked ||
      bpolie_23_checked ||
      bpolie_24_checked ||
      bpolie_25_checked ||
      tpm_22_checked ||
      tpm_23_checked ||
      tpm_24_checked ||
      tpm_25_checked) {
      //If there are any products to be selected add the product attribute
      baseUrl += '&p='

      if (bpoluk_2223_checked) {
        baseUrl += '57,'
      }
      if (bpoluk_2324_checked) {
        baseUrl += '62,'
      }
      if (bpoluk_2425_checked) {
        baseUrl += '67,'
      }
      if (bpoluk_2526_checked) {
        baseUrl += '73,'
      }
      if (bpolie_22_checked) {
        baseUrl += '56,'
      }
      if (bpolie_23_checked) {
        baseUrl += '61,'
      }
      if (bpolie_24_checked) {
        baseUrl += '66,'
      }
      if (bpolie_25_checked) {
        baseUrl += '70,'
      }
      if (tpm_22_checked) {
        baseUrl += '58,'
      }
      if (tpm_23_checked) {
        baseUrl += '63,'
      }
      if (tpm_24_checked) {
        baseUrl += '68,'
      }
      if (tpm_25_checked) {
        baseUrl += '71,'
      }
      
      baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }

    //Key types
    if (bp_ie_22_standard_checked ||
      bp_ie_22_bureau_checked ||
      bp_ie_23_standard_checked ||
      bp_ie_23_bureau_checked ||
      bp_ie_24_standard_checked ||
      bp_ie_24_bureau_checked ||
      bp_ie_25_standard_checked ||
      bp_ie_25_bureau_checked ||
      bp_uk_2223_standard_checked ||
      bp_uk_2223_bureau_checked ||
      bp_uk_2324_standard_checked ||
      bp_uk_2324_bureau_checked ||
      bp_uk_2425_standard_checked ||
      bp_uk_2425_bureau_checked ||
      bp_uk_2526_standard_checked ||
      bp_uk_2526_bureau_checked ||
      tpm_22_standard_checked ||
      tpm_22_bureau_checked ||
      tpm_23_standard_checked ||
      tpm_23_bureau_checked ||
      tpm_24_standard_checked ||
      tpm_24_bureau_checked ||
      tpm_25_standard_checked ||
      tpm_25_bureau_checked ||
      bc_ie_standard_checked ||
      bc_ie_bureau_checked ||
      bc_uk_standard_checked ||
      bc_uk_bureau_checked) {
      baseUrl += '&kt='

      if (bp_ie_22_standard_checked) {
        baseUrl += '1077,';
      }
      if (bp_ie_22_bureau_checked) {
        baseUrl += '1078,';
      }
      if (bp_ie_23_standard_checked) {
        baseUrl += '1086,';
      }
      if (bp_ie_23_bureau_checked) {
        baseUrl += '1087,';
      }
      if (bp_ie_24_standard_checked) {
        baseUrl += '1096,';
      }
      if (bp_ie_24_bureau_checked) {
        baseUrl += '1097,';
      }
      if (bp_ie_25_standard_checked) {
        baseUrl += '1106,';
      }
      if (bp_ie_25_bureau_checked) {
        baseUrl += '1107,';
      }
      if (bp_uk_2223_standard_checked) {
        baseUrl += '1080,';
      }
      if (bp_uk_2223_bureau_checked) {
        baseUrl += '1081,';
      }
      if (bp_uk_2324_standard_checked) {
        baseUrl += '1089,';
      }
      if (bp_uk_2324_bureau_checked) {
        baseUrl += '1090,';
      }
      if (bp_uk_2425_standard_checked) {
        baseUrl += '1098,';
      }
      if (bp_uk_2425_bureau_checked) {
        baseUrl += '1099,';
      }
      if (bp_uk_2526_standard_checked) {
        baseUrl += '1112,';
      }
      if (bp_uk_2526_bureau_checked) {
        baseUrl += '1113,';
      }
      if (tpm_22_standard_checked) {
        baseUrl += '1082,';
      }
      if (tpm_22_bureau_checked) {
        baseUrl += '1083,';
      }
      if (tpm_23_standard_checked) {
        baseUrl += '1091,';
      }
      if (tpm_23_bureau_checked) {
        baseUrl += '1092,';
      }
      if (tpm_24_standard_checked) {
        baseUrl += '1100,';
      }
      if (tpm_24_bureau_checked) {
        baseUrl += '1101,';
      }
      if (tpm_25_standard_checked) {
        baseUrl += '1108,';
      }
      if (tpm_25_bureau_checked) {
        baseUrl += '1109,';
      }
      if (bc_ie_standard_checked) {
        baseUrl += '3,';
      }
      if (bc_ie_bureau_checked) {
        baseUrl += '4,';
      }
      if (bc_uk_standard_checked) {
        baseUrl += '10,';
      }
      if (bc_uk_bureau_checked) {
        baseUrl += '11,';
      }

      baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }

    if (employeeCount !== '') {
      baseUrl += '&q=' + employeeCount
    }

    if (voucherCode !== '') {
      baseUrl += '&v=' + voucherCode
    }

    if (paymentType !== 'either') {
      baseUrl += '&f_pmt=' + paymentType
    }

    if (filterLicences == 'true') {
      baseUrl += '&f_akt=true'
    }

    setUrlText(baseUrl);

    navigator.clipboard.writeText(baseUrl)
  }

  return (
    <div className="App">
      <div className="main-body">
        <br />
        <img src={require('./bright-id-flat-on-dark.png')} class="header-logo" />
        <br />
        <div><h1><u>Bright ID Licence URL Creator</u></h1></div>
        <br />
        <Container>
          <Row>
            <Col></Col>
            <Col xs={6}>
              <Form>
                <Form.Label><u>Region</u></Form.Label>
                <Form.Select aria-label="Region" value={region} onChange={(e) => setRegion(e.currentTarget.value)}>
                  <option value="GB">GB</option>
                  <option value="IE">IE</option>
                </Form.Select>
                <br />

                <Form.Check type='checkbox' label={`BrightPay (UK) 2022/23`} checked={bpoluk_2223_checked} onChange={e => setBpoluk_2223_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2023/24`} checked={bpoluk_2324_checked} onChange={e => setBpoluk_2324_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2024/25`} checked={bpoluk_2425_checked} onChange={e => setBpoluk_2425_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2025/26`} checked={bpoluk_2526_checked} onChange={e => setBpoluk_2526_checked(e.currentTarget.checked)} />

                <Form.Check type='checkbox' label={`BrightPay (IE) 2022`} checked={bpolie_22_checked} onChange={e => setBpolie_22_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (IE) 2023`} checked={bpolie_23_checked} onChange={e => setBpolie_23_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (IE) 2024`} checked={bpolie_24_checked} onChange={e => setBpolie_24_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (IE) 2025`} checked={bpolie_25_checked} onChange={e => setBpolie_25_checked(e.currentTarget.checked)} />

                <Form.Check type='checkbox' label={`TPM 2022`} checked={tpm_22_checked} onChange={e => setTpm_22_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`TPM 2023`} checked={tpm_23_checked} onChange={e => setTpm_23_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`TPM 2024`} checked={tpm_24_checked} onChange={e => setTpm_24_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`TPM 2025`} checked={tpm_25_checked} onChange={e => setTpm_25_checked(e.currentTarget.checked)} />
                <br />

                <Form.Label><u>Key Type ID(s)</u></Form.Label>
                <Form.Check type='checkbox' label={`BrightPay (IE) 2022, Standard`} checked={bp_ie_22_standard_checked} onChange={e => setBp_ie_22_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (IE) 2022, Bureau`} checked={bp_ie_22_bureau_checked} onChange={e => setbp_Ie_22_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (IE) 2023, Standard`} checked={bp_ie_23_standard_checked} onChange={e => setBp_ie_23_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (IE) 2023, Bureau`} checked={bp_ie_23_bureau_checked} onChange={e => setBp_ie_23_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (IE) 2024, Standard`} checked={bp_ie_24_standard_checked} onChange={e => setBp_ie_24_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (IE) 2024, Bureau`} checked={bp_ie_24_bureau_checked} onChange={e => setBp_ie_24_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (IE) 2025, Standard`} checked={bp_ie_25_standard_checked} onChange={e => setBp_ie_25_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (IE) 2025, Bureau`} checked={bp_ie_25_bureau_checked} onChange={e => setBp_ie_25_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2022/23, Standard`} checked={bp_uk_2223_standard_checked} onChange={e => setBp_uk_2223_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2022/23, Bureau`} checked={bp_uk_2223_bureau_checked} onChange={e => setBp_uk_2223_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2023/24, Standard`} checked={bp_uk_2324_standard_checked} onChange={e => setBp_uk_2324_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2023/24, Bureau`} checked={bp_uk_2324_bureau_checked} onChange={e => setBp_uk_2324_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2024/25, Standard`} checked={bp_uk_2425_standard_checked} onChange={e => setBp_uk_2425_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2024/25, Bureau`} checked={bp_uk_2425_bureau_checked} onChange={e => setBp_uk_2425_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2025/26, Standard`} checked={bp_uk_2526_standard_checked} onChange={e => setBp_uk_2526_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`BrightPay (UK) 2025/26, Bureau`} checked={bp_uk_2526_bureau_checked} onChange={e => setBp_uk_2526_bureau_checked(e.currentTarget.checked)} />

                <Form.Check type='checkbox' label={`TPM 2022, Standard`} checked={tpm_22_standard_checked} onChange={e => setTpm_22_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`TPM 2022, Bureau`} checked={tpm_22_bureau_checked} onChange={e => setTpm_22_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`TPM 2023, Standard`} checked={tpm_23_standard_checked} onChange={e => setTpm_23_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`TPM 2023, Bureau`} checked={tpm_23_bureau_checked} onChange={e => setTpm_23_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`TPM 2024, Standard`} checked={tpm_24_standard_checked} onChange={e => setTpm_24_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`TPM 2024, Bureau`} checked={tpm_24_bureau_checked} onChange={e => setTpm_24_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`TPM 2025, Standard`} checked={tpm_25_standard_checked} onChange={e => setTpm_25_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`TPM 2025, Bureau`} checked={tpm_25_bureau_checked} onChange={e => setTpm_25_bureau_checked(e.currentTarget.checked)} />

                <Form.Check type='checkbox' label={`Bright Contracts (IE) - Standard`} checked={bc_ie_standard_checked} onChange={e => setBc_ie_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`Bright Contracts (IE) - Bureau`} checked={bc_ie_bureau_checked} onChange={e => setBc_ie_bureau_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`Bright Contracts (UK) - Standard`} checked={bc_uk_standard_checked} onChange={e => setBc_uk_standard_checked(e.currentTarget.checked)} />
                <Form.Check type='checkbox' label={`Bright Contracts (UK) - Bureau`} checked={bc_uk_bureau_checked} onChange={e => setBc_uk_bureau_checked(e.currentTarget.checked)} />
                <br />

                <Form.Label><u>Number of Employees</u></Form.Label>
                <Form.Control type="number" placeholder="Enter number of employees" value={employeeCount} onChange={(e) => setNumberOfEmployees(e.currentTarget.value)} />
                <br />

                <Form.Label><u>Payment Type</u></Form.Label>
                <Form.Select aria-label="Payment Type" value={paymentType} onChange={(e) => setPaymentType(e.currentTarget.value)}>
                  <option value="either">Either</option>
                  <option value="card">Card</option>
                  <option value="debit">Debit</option>
                </Form.Select>
                <br />

                <Form.Label><u>Filter licences to only those selected above</u></Form.Label>
                <Form.Select aria-label="Filter Licences" value={filterLicences} onChange={(e) => setfilterLicences(e.currentTarget.value)}>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Form.Select>
                <br />

                <Form.Label><u>Voucher</u></Form.Label>
                <Form.Control type="text" placeholder="Enter voucher code" value={voucherCode} onChange={(e) => setVoucherCode(e.currentTarget.value)} />
                <br />
                <Button onClick={buildUrl}>Generate URL (and copy to clipboard)</Button>
                <Form.Control disabled type="text" placeholder="Enter text" value={urlText} />
              </Form>
              <br />
              <br />

            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}


export default App;